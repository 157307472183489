<template>
  <v-card>
    <v-container fluid >
      <h3>Geodaten Auswahl</h3>
      <div v-for="(order, index) in orders" :key="index">
        <v-row align="left">
          <v-col cols="12">
            <v-select class="ma-2"
              v-model="order.community"
              :items="communities"
              item-value="id"
              item-title="name"
              dense
              label="Wählen Sie eine Gemeinde"
            ></v-select>
          </v-col>
          <v-simple-table class="media-table" v-if="order.community">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Produkt</th>
                <th class="text-left">Papierplan</th>
                <th class="text-left">PDF</th>
                <th class="text-left">Digitale Daten (DXF/DWG)</th>
              </tr>
            </thead>
            <tbody>
              <!-- Loop through each grouped product by name -->
              <tr v-for="(productGroup, index) in groupedProducts" :key="index">
              <td>{{ productGroup.name }}</td>
              <td v-if="productGroup.paper && productGroup.paper.name">
                {{ productGroup.paper.name }} - Preis: {{ productGroup.paper.price }}
              </td>
              <td v-if="productGroup.pdf && productGroup.pdf.name">
                {{ productGroup.pdf.name }} - Preis: {{ productGroup.pdf.price }}
              </td>
              <td v-if="productGroup.dxf && productGroup.dxf.name">
                {{ productGroup.dxf.name }} - Preis: {{ productGroup.dxf.price }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
            <v-col cols="12">
              <v-autocomplete
                v-model="order.media"
                :items="media"
                :disabled="!order.community"
                dense
                filled
                label="Wählen Sie die Daten"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="order.media === 'Amtliche Vermessung'" cols="12">
              <v-autocomplete
                v-model="order.dataFormat"
                :items="dataformatAv"
                :disabled="order.media !== 'Amtliche Vermessung'"
                multiple
                dense
                filled
                label="Wählen Sie das Datenformat für die Amtliche Vermessung"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="order.media === 'Amtliche Vermessung'" cols="12">
              <v-checkbox
                v-model="order.checkedArray"
                label="Neu Wählen Sie das Datenformat für die Amtliche Vermessung"
                value="option1"
              ></v-checkbox>
            </v-col>
            <v-col v-if="order.media === 'Werkleitungen'" cols="12">
              <v-autocomplete
                v-model="order.dataFormat"
                :items="dataformatWl"
                :disabled="order.media !== 'Werkleitungen'"
                multiple
                dense
                filled
                label="Wählen Sie das Datenformat für die Werkleitungen"
              ></v-autocomplete>
            </v-col>
            <h3>Ausschnitt</h3>
            <v-col cols="12">
              <v-text-field
                v-model="order.orderaddress"
                label="Adresse"
                required
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="order.orderparcel"
                label="Parzellennummer"
                required
                type="text"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn @click="removeOrder(index)" color="rgba(189,16,32)" class="ma-2">Entfernen</v-btn>
        </div>

        <v-btn @click="addOrder" class="ma-2" color="rgba(32,16,189)">Weitere Geodaten Auswahl hinzufügen</v-btn>
      
        <h3>Bestellangaben</h3>
        <v-container fluid>
          <v-checkbox label="checkbox" v-model="checkbox">
            <template v-slot:label>
              <div>Separate Rechnungsadresse</div>
            </template>
          </v-checkbox>
          <v-text-field
            label="Vor- und Nachname / Firmenname"
            v-model="mainOrder.name"
            required
            type="text"
          ></v-text-field>
          <v-text-field
            label="Adresszusatz"
            v-model="mainOrder.addressaddition"
            required
            type="text"
          ></v-text-field>
          <v-text-field
            label="Adresse"
            v-model="mainOrder.address"
            required
            type="text"
          ></v-text-field>
          <v-text-field
            label="Postleitzahl"
            v-model="mainOrder.postcode"
            required
            type="text"
          ></v-text-field>
          <v-text-field
            label="Ort"
            v-model="mainOrder.place"
            required
            type="text"
          ></v-text-field>
          <v-text-field
            label="Telefonnummer"
            v-model="mainOrder.phonenumber"
            required
            type="text"
          ></v-text-field>
          <v-text-field
            label="E-Mail"
            v-model="mainOrder.mail"
            required
            type="email"
          ></v-text-field>
          <v-text-field
            label="Bemerkungen zur Bestellungen oder Versandt"
            v-model="mainOrder.comments"
            required
            type="text"
          ></v-text-field>
        </v-container>

        <v-btn @click="submitOrder" class="ma-2" color="red"> Senden </v-btn>
      </v-container>
  </v-card>
</template>

<script>

import axios from "axios";

export default {
  name: "OrderCreate",
  data() {
    return {
      communities: [],
      media: [],
      dataformatAv: [],
      dataformatWl: [],
      orders: [
        {
          community: null,
          media: "",
          dataFormat: [],
          orderaddress: "",
          orderparcel: "",
          checkedArray: [],
        },
      ],
      mainOrder: {
        name: "",
        addressAddition: "",
        address: "",
        postcode: "",
        place: "",
        phonenumber: "",
        mail: "",
        comments: "",
      },
      checkbox: false,
      selectedProducts: [],
    };
  },
  computed: {
    groupedProducts() {
      const grouped = {};

      this.selectedProducts.forEach(task => {
        if (!grouped[task.name]) {
            grouped[task.name] = { name : task.name, paper: null, pdf: null, dxf: null };
          }
       task.description.forEach(product => {
          if (product.type === 'paper') {
            grouped[task.name].paper = product;
          } else if (product.type === 'pdf') {
            grouped[task.name].pdf = product;
          } else if (product.type === 'dxf') {
            grouped[task.name].dxf = product;

          }
        });
      });
      return Object.values(grouped);
    },
  },
  watch: {
    orders: {
      handler(newOrders) {
        newOrders.forEach((newOrder) => {
          if (newOrder.community) {
            this.getServiceByCommune(newOrder.community);
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.getCommunities();
  },
  methods: {
    addOrder() {
      this.orders.push({
        community: "",
        media: "",
        dataFormat: [],
        orderaddress: "",
        orderparcel: "",
        checkedArray: [],
      });
    },
    removeOrder(index) {
      this.orders.splice(index, 1);
    },
    async submitOrder() {
      try {
        const payload = {
          orders: this.orders,
          mainOrder: this.mainOrder,
        };
        await axios.post(`${process.env.VUE_APP_ZKL_BACKEND_URL}/order`, payload);
        this.resetForm();
      } catch (error) {
        console.error("Error submitting order:", error);
      }
    },
    resetForm() {
      this.orders = [
        {
          community: "",
          media: "",
          dataFormat: [],
          orderaddress: "",
          orderparcel: "",
          checkedArray: [],
        },
      ];
      this.mainOrder = {
        name: "",
        addressAddition: "",
        address: "",
        postcode: "",
        place: "",
        phonenumber: "",
        mail: "",
        comments: "",
      };
      this.checkbox = false;
    },
    getCommunities() {
      axios
        .get(`${process.env.VUE_APP_ZKL_BACKEND_URL}/v1/services/municipality`)
        .then(result => {
          this.communities = result.data;
        });
    },
    getServiceByCommune(id) {
      axios
        .get(`${process.env.VUE_APP_ZKL_BACKEND_URL}/v1/services/tasks/${id}`)
        .then(result => {
          const { products } = result.data;
          this.selectedProducts = products; 
        })
        .catch(error => {
          console.error("Error fetching services:", error);
        });
    },
  },
};

</script>

<style scoped>
  .pa-3 {
    font: Verdana, Arial, sans-serif;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    text-align: center;
  }
  .media-table {
    width: 80%;
    margin: auto;
    padding: 10px;
  }
  .media-table th,
  .media-table td {
    height: 100px;
    width: 200px;
    border: 1px solid black !important; /* Black border around each cell */
    padding: 10px; /* Padding around table cells */
  }
  .media-table th {
    height: 100px;
    border: 2px;
    background-color: white; /* Blue header background */
    color: black; /* White text color */
  }
  .media-table tr:hover {
    height: 100px;
    border: 2px;
    width: 200px;
    background-color: #e3f2fd; /* Light blue hover effect */
  }
</style>